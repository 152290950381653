import React from 'react';

const IconLoader = () => (
  <svg id="Logo" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 87">
    <title>Logo</title>
    <polygon
      points="1.589 22.322 1.589 64.322 37.963 85.322 74.336 64.322 74.336 22.322 37.963 1.322 1.589 22.322"
      style={{ fill: 'none', stroke: '#64ffda', strokeMiterLimit: '10', strokeWidth: '5' }}
    />
    <path
      d="M52.708,35.74756a1.48563,1.48563,0,0,1-.10059.55029q-.07909.14649-.46094.21338a.84879.84879,0,0,1-1.10058-.62891,7.984,7.984,0,0,0-2.29053-3.97558q-1.842-1.36964-6.44629-1.37012-1.86474,0-2.04394.4043a.87251.87251,0,0,0-.06739.38183V53.71582a2.52861,2.52861,0,0,0,.67383,2.01074,6.90942,6.90942,0,0,0,2.67285,1.044,5.77066,5.77066,0,0,1,.96582.24707q.31422.20214.22461.85351c-.04492.34473-.21.53125-.49414.56153a6.7305,6.7305,0,0,1-.9209-.06739,48.61677,48.61677,0,0,0-9.793-.04492,2.93806,2.93806,0,0,1-1.19043,0,.67174.67174,0,0,1-.292-.53906q-.18016-.71778.78614-.96582A5.693,5.693,0,0,0,35.436,55.625a3.54881,3.54881,0,0,0,.76367-2.53809V31.32275q0-.65112-.8086-.76367-.09009-.022-1.32519-.02246-4.26783,0-6.28906,1.48242a9.07361,9.07361,0,0,0-2.71778,4.043q-.38232.92139-1.05566.60645-.76392-.17945-.56153-.83106a23.25358,23.25358,0,0,0,.78614-6.85058q0-.51636,1.37011-.42676,4.85157.31494,10.73633.31445a139.65028,139.65028,0,0,0,14.4419-.44922q1.25829-.17945,1.37011.4043A31.26058,31.26058,0,0,1,52.708,35.74756Z"
      style={{ fill: '#64ffda' }}
    />
  </svg>
);

export default IconLoader;
